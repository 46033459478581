<template>
  <div class="columns">
    <div class="column is-3" v-if="$screen.width > 768">
      <banner-left
        :pasiva="getLinea.pasiva ? true : false"
        :image="image"
        titulo="Colaboradores"
        texto="Los colaboradores son las personas relacionadas con la línea de 
        crédito que de alguna manera tienen que revisar, manejar o decidir sobre
        esta. Si los agregas como colaboradores, les llegarán notificaciones de 
        esta."
      />
    </div>
    <div class="column is-9">
      <div class="columns is-multiline mb-0">
        <div class="column is-12 text-left">
          <p class="text-2xl font-bold">Agregar personas</p>
          <p class="has-text-grey-light">Línea #{{ $route.params.idLinea }}</p>
        </div>
        <div class="column is-3 text-left">
          <valid-auto-complete
            rules="none"
            label="Buscar y añadir personas"
            placeholder="Buscar..."
            v-model="personaSearch"
            :data="getPersonas"
            show="alias"
            class="text-left"
            @typing="buscarPersonas"
            @select="setColaborador"
            :expanded="true"
          />
        </div>
      </div>
      <div class="columns" v-if="sugerencias.length > 0">
        <div class="column is-12 text-left">
          <p class="ml-4 text-xl">Sugerencias</p>
          <b-carousel-list
            v-model="activePersona"
            class="p-3 shadow-none"
            :data="sugerencias"
            :arrow="true"
            :arrow-hover="true"
            :items-to-list="1"
            v-bind="configCarousel"
            :repeat="true"
            :indicator="true"
            :indicator-inside="false"
            :has-drag="true"
            :has-opacity="true"
            @switch="val => (activePersona = val)"
          >
            <template slot="item" slot-scope="props">
              <div class="px-3">
                <persona-card
                  appendToBody
                  :add="true"
                  :persona="props"
                  @added="relacionar"
                />
              </div>
            </template>
          </b-carousel-list>
        </div>
      </div>
      <div class="border" v-if="sugerencias.length > 0" />
      <div class="columns is-multiline mt-2">
        <div
          v-for="relacion in getLinea.relaciones"
          :key="relacion.id"
          class="column is-3"
        >
          <persona-card
            :index="relacion.id"
            editable
            :persona="relacion"
            @delete="deleteRelacion"
          />
        </div>
      </div>
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="isModalOpen"
        :active.sync="isModalOpen"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-persona-rol
          class="modal-has-overflow"
          :persona2="persona2.nombre_completo.split('|')[0]"
          :persona1="persona1.relacionado.nombre_completo.split('|')[0]"
          @agregar="agregarPersona"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import BannerLeft from "@/components/banners/BannerLeft";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { debounce } from "@/vendors/helpers";
import { mapGetters } from "vuex";
import PersonaCard from "@/components/cards/PersonaCard";
import ModalPersonaRol from "@/components/modals/ModalPersonaRol";

export default {
  name: "Colaboradores",
  components: {
    BannerLeft,
    ValidAutoComplete,
    PersonaCard,
    ModalPersonaRol
  },
  data() {
    return {
      image: "/images/colaboradores.svg",
      personaSearch: null,
      persona2: null,
      persona1: null,
      isModalOpen: false,
      activePersona: null,
      configCarousel: {
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 3
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 4
          },
          1200: {
            hasGrayscale: true,
            itemsToShow: 5
          }
        }
      }
    };
  },
  methods: {
    buscarPersonas: debounce(function(nombre) {
      if (!nombre.length) {
        return;
      }
      this.$store.dispatch("catalogos/buscarPersonas", nombre);
    }, 400),
    setColaborador(data) {
      this.persona2 = data;
      this.isModalOpen = true;
    },
    agregarPersona(rol) {
      this.$store
        .dispatch("personas/relacionar", {
          persona1: this.persona1.relacionado.id,
          persona2: this.persona2.id,
          roles_id: rol
        })
        .then(data => {
          this.$store.dispatch("lineascredito/relacionar", {
            linea_id: this.$route.params.idLinea,
            relacion_id: data.id
          });
        });
    },
    deleteRelacion(relacion_id) {
      this.$store.dispatch("lineascredito/quitarRelacion", {
        linea_id: this.$route.params.idLinea,
        relacion_id: relacion_id
      });
    },
    relacionar(relacion_id) {
      this.$store.dispatch("lineascredito/relacionar", {
        linea_id: this.$route.params.idLinea,
        relacion_id: relacion_id
      });
    }
  },
  computed: {
    ...mapGetters("lineascredito", ["getLinea"]),
    ...mapGetters("catalogos", ["getPersonas", "getRoles"]),
    ...mapGetters("personas", ["getRelaciones"]),
    sugerencias() {
      if (!this.getRelaciones) {
        return [];
      }
      return this.getRelaciones.filter(
        x => !this.getLinea.relaciones.some(persona => persona.id === x.id)
      );
    }
  },
  mounted() {
    if (this.getRoles == null) {
      this.$store.dispatch("catalogos/getRoles");
    }
    this.persona1 = this.getLinea.relaciones.filter(
      x => x.rol.nombre == "Cliente"
    )[0];
    this.$store.dispatch(
      "personas/getRelaciones",
      this.persona1.relacionado.id
    );
  }
};
</script>
